(function () {
  'use strict';

  angular
  .module('neo.home.entitats')
  .config(config);

  function config($stateProvider) {
    $stateProvider.state('home.entitats', {
      url: '/entitats',
      templateUrl: 'home/entitats/entitats.tpl.html',
      controller: 'EntitatsCtrl',
      controllerAs: 'vm',
      resolve: {
        entitats: function (Entitats, $q) {
          var deferred = $q.defer();

          Entitats.query(
            function (data) {
              deferred.resolve(data);
            },
            function (response) {
              deferred.reject(response);
            });
          return deferred.promise;
        },
        towns: function (Poblacions, $q) {
          var deferred = $q.defer();

          Poblacions.query(
            function (data) {
              deferred.resolve(data);
            },
            function (response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        entityTypes: function (EntityTypesPublic) {
          return EntityTypesPublic.query().$promise;
        }
      }
    });
  }
}());
